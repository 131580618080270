/* tslint:disable */
/* eslint-disable */
/**
 * SLOeats API
 * Interal API to the SLOeats platform.
 *
 * The version of the OpenAPI document: 
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `lack_of_deals` - Restaurants I wanted don't offer deals
 * * `not_worth_it` - The deals aren't worth $9/month
 * * `not_eating_out` - I don't eat out as much as I thought
 * * `left_city` - I no longer live in a SLOeats city
 * * `reducing_subscriptions` - I'm cutting down on subscriptions
 * * `missing_features` - SLOeats doesn't have a feature I want
 * * `other` - Other
 * @export
 */
export const ReasonEnum = {
    lack_of_deals: 'lack_of_deals',
    not_worth_it: 'not_worth_it',
    not_eating_out: 'not_eating_out',
    left_city: 'left_city',
    reducing_subscriptions: 'reducing_subscriptions',
    missing_features: 'missing_features',
    other: 'other'
} as const;
export type ReasonEnum = typeof ReasonEnum[keyof typeof ReasonEnum];


export function ReasonEnumFromJSON(json: any): ReasonEnum {
    return ReasonEnumFromJSONTyped(json, false);
}

export function ReasonEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReasonEnum {
    return json as ReasonEnum;
}

export function ReasonEnumToJSON(value?: ReasonEnum | null): any {
    return value as any;
}

